module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.10.0_gatsby@5.10.0_graphql@16.6.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/src/web/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"89108f45b25e44140ba3053be14f15c0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.10.0_babel-eslint@10.1.0_eslint-plugin-testing-library@5.11.0_react-dom@18.2.0_react@18.2.0_typescript@5.0.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
